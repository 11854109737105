import React, { useState } from "react"
import "./assets/style.scss"
import Square from "../MyLearning/assets/images/SquaresFour.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Program from "./assets/images/Program.svg"
import StudentLearning from "../MyLearning/assets/images/StudentLearning.svg";
import Duration from "../MyLearning/assets/images/Duration.svg";
import Language from "../MyLearning/assets/images/Language.svg";
import archive from "../MyLearning/assets/images/archive.svg";
import Calendar from "./assets/images/Calendar.svg"
import { Link } from "react-scroll";
import Collapse from "../../customElement/Collapse"
import MentorImage from "./assets/images/MentorImage.svg"
import Certificate from "./assets/images/certificate.svg"
import Star from "./assets/images/star.svg"
import RatingBar from "./assets/images/RatingBar.svg"
import User from "./assets/images/User.svg"
import Like from "../../pages/CourseInfo/assets/images/Like.svg"
import Heart from "../../pages/CourseInfo/assets/images/Heart.svg"

const JobGuaranteeDetails = () => {
    const [wishlist, setWishlist] = useState(true)
    return (
        <div className="job-guarantee-details-header">
            <div className="my-learnings-flex">
                <div>
                    <img src={Square} alt="square-box" style={{ color: "#000000", opacity: "0.5" }} />
                </div>
                <div>
                    <ArrowForwardIosIcon style={{ color: "#000000", opacity: "0.5", fontSize: "20px", marginLeft: "27px" }} />
                </div>

                <div>
                    <h3 className="learnings-text">My Learnings</h3>
                </div>
            </div>
            <div className="c-h-program-details">
                <div>
                    <img alt="user" className="c-h-program-course" src={Program} />
                </div>
                <div className="learning-course-intro">
                    <h3>Crack Interview like a pro</h3>
                    <p>by Vijay Sethi</p>
                    <div className="learning-course-description" style={{ display: "flex", marginTop: "10px" }}>
                        <div>
                            <img alt="user" src={StudentLearning} />
                        </div>
                        <div>
                            <p className="image-text">Students, Professionals, Entreprneurs</p>
                        </div>
                    </div>
                    <div className="learning-course-description" style={{ display: "flex", marginTop: "10px" }}>
                        <div>
                            <img alt="user" src={Duration} />
                        </div>
                        <div>
                            <p className="image-text"> 1 month course starting 15th Jan</p>
                        </div>
                    </div>
                    <div className="learning-course-description" style={{ display: "flex", marginTop: "10px" }}>
                        <div>
                            <img alt="user" src={Language} />
                        </div>
                        <div>
                            <p className="image-text">Hindi, English</p>
                        </div>
                    </div>
                </div>
                <div className="archive-like-btn">
                    <div className="like-btn">
                        {
                            wishlist ?
                                <img src={Heart} alt="wishlist" onClick={() => setWishlist(false)} style={{ width: '34px' }} />
                                :
                                <img src={Like} alt="wishlist" onClick={() => setWishlist(true)} style={{ width: '34px' }} />
                        }
                    </div>
                    <div className="like-btn">
                        <img alt="user" src={archive} />
                    </div>
                </div>

                <div className="c-h-course-card-header">
                    <h2>Start this course</h2>
                    <div className="c-h-course-card">
                        <div style={{ display: "flex" }}>
                            <img alt="user" src={Calendar} />
                            <p>2 week course</p>
                        </div>
                        <h5>₹ 6000</h5>
                        <h2>Grow a Business Online From Scratch</h2>
                        <h2>Grow a Business Online From Scratch</h2>
                        <h2>Grow a Business Online From Scratch</h2>
                        <h2>Grow a Business Online From Scratch</h2>
                        <a href="/confirm-order">
                            <button className="apply-now-btn">Apply Now</button>
                        </a>
                    </div>
                </div>
            </div>
            <div className="c-h-program-links">
                <Link activeClass="active" to="about-course" spy={true} smooth={true}>
                    <p className="active-header">About Course</p>
                </Link>
                <Link activeClass="active" to="course-content" spy={true} smooth={true}>
                    <p className="active-header">Course content</p>
                </Link>
                <Link activeClass="active" to="mentor" spy={true} smooth={true}>
                    <p className="active-header">Mentors</p>
                </Link>
                <Link activeClass="active" to="Requirements" spy={true} smooth={true}>
                    <p className="active-header">Requirements</p>
                </Link>
                <Link activeClass="active" to="Certificate" spy={true} smooth={true}>
                    <p className="active-header">Certificate</p>
                </Link>
                <Link activeClass="active" to="Reviews" spy={true} smooth={true}>
                    <p className="active-header">Reviews</p>
                </Link>
            </div>
            <div className="about-courses" id="about-course">
                <h3>About Course</h3>
                <p>With over 35 hours of Live session, this is one of the most comprehensive digital marketing courses available. We'll cover SEO, YouTube Marketing, Facebook Marketing, Google Adwords, Google Analytics and more!
                    Learn by mentors, assignments and quizzes. Learn step by step how to market a business online from scratch across all the major marketing channels
                    By the end of this course, you will be confidently implementing marketing strategies across the major online marketing channels.</p>
                <h3 style={{ marginTop: "32px" }}>Who should join</h3>
                <ul className="about-courses-ul">
                    <li>Pre launch business owners who don't know where to get started</li>
                    <li>Pre launch business owners who don't know where to get started</li>
                    <li>Pre launch business owners who don't know where to get started</li>
                    <li>Pre launch business owners who don't know where to get started</li>
                </ul>
            </div>
            <div className="about-courses" id="course-content">
                <h3>Course Content</h3>
                <Collapse

                    title={<div className='title'>INTRODUCTION TO MARKETING (3hrs)- Sayan Dutta</div>}
                    body={
                        <div className="title-answer"
                            dangerouslySetInnerHTML={{ __html: "1 Course description" }}
                        />
                    }
                    className='c-i-content-collapse'
                />
                <Collapse

                    title={<div className='title'>INTRODUCTION TO MARKETING (3hrs)- Sayan Dutta</div>}
                    body={
                        <div className="title-answer"
                            dangerouslySetInnerHTML={{ __html: "1 Course description" }}
                        />
                    }
                    className='c-i-content-collapse'
                />
                <Collapse

                    title={<div className='title'>INTRODUCTION TO MARKETING (3hrs)- Sayan Dutta</div>}
                    body={
                        <div className="title-answer"
                            dangerouslySetInnerHTML={{ __html: "1 Course description" }}
                        />
                    }
                    className='c-i-content-collapse'
                />
            </div>
            <div className="about-courses" id="mentor">
                <h3>Mentors who’ll be teaching you</h3>
                <div className="c-h-mentor-card">
                    <div className="c-h-mentor-inner-card">
                        <div className="c-h-mentor-card-img">
                            <img alt="user" src={MentorImage} />
                        </div>
                        <h1>Ashish Khare</h1>
                        <h4>Entrepreneur | HR Consultant</h4>
                    </div>
                    <div className="c-h-mentor-inner-card">
                        <div className="c-h-mentor-card-img">
                            <img alt="user" src={MentorImage} />
                        </div>
                        <h1>Ashish Khare</h1>
                        <h4>Entrepreneur | HR Consultant</h4>
                    </div>
                    <div className="c-h-mentor-inner-card">
                        <div className="c-h-mentor-card-img">
                            <img alt="user" src={MentorImage} />
                        </div>
                        <h1>Ashish Khare</h1>
                        <h4>Entrepreneur | HR Consultant</h4>
                    </div>
                    <div className="c-h-mentor-inner-card">
                        <div className="c-h-mentor-card-img">
                            <img alt="user" src={MentorImage} />
                        </div>
                        <h1>Ashish Khare</h1>
                        <h4>Entrepreneur | HR Consultant</h4>
                    </div>

                </div>
            </div>
            <div className="about-courses" id="Requirements">
                <h3>Requirements</h3>
                <ul className="about-courses-ul">
                    <li>Pre launch business owners who don't know where to get started</li>
                    <li>Pre launch business owners who don't know where to get started</li>
                    <li>Pre launch business owners who don't know where to get started</li>
                    <li>Pre launch business owners who don't know where to get started</li>
                </ul>
            </div>
            <div className="about-courses" id="Certificate">
                <h3>Certificate</h3>
                <img style={{ marginTop: "16px" }} className="c-h-feedback-certificate" src={Certificate} alt="Certificate" />
            </div>
            <div className="about-courses" id="Reviews">
                <h3>Reviews</h3>
                <div className="c-h-rating-flex">
                    <div className="c-h-rating-left">
                        <h6 className="rating-point">4.8</h6>
                        <div>
                            <img alt="user" src={Star} />
                            <img alt="user" src={Star} />
                            <img alt="user" src={Star} />
                            <img alt="user" src={Star} />
                            <img alt="user" src={Star} />
                        </div>
                        <h4 className="rating-score">Rating (562)</h4>
                    </div>
                    <div>
                        <div className="c-h-rating-bar">
                            <p>5</p>
                            <img src={Star} alt="star" />
                            <img src={RatingBar} alt="Rating bar" />
                        </div>
                        <div className="c-h-rating-bar">
                            <p>5</p>
                            <img src={Star} alt="star" />
                            <img src={RatingBar} alt="Rating bar" />
                        </div>
                        <div className="c-h-rating-bar">
                            <p>5</p>
                            <img src={Star} alt="star" />
                            <img src={RatingBar} alt="Rating bar" />
                        </div>
                        <div className="c-h-rating-bar">
                            <p>5</p>
                            <img src={Star} alt="star" />
                            <img src={RatingBar} alt="Rating bar" />
                        </div>
                        <div className="c-h-rating-bar">
                            <p>5</p>
                            <img src={Star} alt="star" />
                            <img src={RatingBar} alt="Rating bar" />
                        </div>
                    </div>
                </div>
                <div className="c-h-rating-user">
                    <div className="c-h-user-inner">
                        <div>
                            <img alt="user" src={User} />
                        </div>
                        <div style={{ marginLeft: "15px" }}>
                            <h2 className="c-h-user-name">Jacob</h2>
                            <img alt="user" src={Star} />
                            <img alt="user" src={Star} />
                            <img alt="user" src={Star} />
                            <img alt="user" src={Star} />
                            <img alt="user" src={Star} />
                        </div>
                    </div>

                    <div>
                        <h6 className="c-h-user-time">2 days ago</h6>
                    </div>
                </div>
                <p className="c-h-user-review-description">Ask CDCR San Quintin State Prison 2008. We installed Purex dispensers throughout the prison to combat diseases…and it was a Roaring Success (as in Roaring Drunk) I mean we had Long lines of prisoners fist fighting to use them.</p>
            </div>
        </div>
    )
}

export default JobGuaranteeDetails