import React from 'react';
import './index.css';
import App from './App';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from "./registerServiceWorker";
import TagManager from 'react-gtm-module';
import './fcm';
import { Provider } from 'react-redux';
import store from './config/store'
const tagManagerArgs = {
    gtmId: 'GTM-P2V4RFK'
}
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
  , document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
TagManager.initialize(tagManagerArgs);
serviceWorker.register();
reportWebVitals();
